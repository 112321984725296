<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <v-text-field
            v-model="search"
            label="Поиск"
            height="44"
            hide-details
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            class="mb-5"
            @change="getList"
        >
        </v-text-field>
        <v-data-table
            :headers="headers"
            :items="items.results"
            disable-sort
            :disable-pagination="true"
            :hide-default-footer="true"
        >
        </v-data-table>
    </div>
</template>

<script>
import vueTableActions from '@/components/ui/vueTableActions.vue'
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import {tourAgentService} from '@/services/tourAgent.service.js'
export default {
    components: {
        vueTableActions,
        VueBreadcrumbs
    },
    data: () => ({
        search: null,
        items: [],
        headers: [
            { text: 'ФИО сотрудника', value: 'full_name' },
            { text: 'Email', value: 'email' },
        ]
    }),
    computed: {
        breadCrumbs(){
            return [
                {
                    text: 'Мои турагенты',
                    to: '/myTourAgents'
                },
                {
                    text: 'Сотрудник',
                    to: `/myTourAgents/${this.$route.params.id}`,
                    disabled: true,
                }
            ]
        }
    },
    created(){
        this.getList()
    },
    methods: {
        async getList(){
            let params = {
                tour_access: this.$route.params.id, 
                search: this.search, 
                page: 1, 
                size: 100000, 
            }
            this.items = await tourAgentService.getMyAgentEmployees(params)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>